.debug * {
  outline: 0.013333rem solid gold;
}

.debug-grid {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAFElEQVR4AWPAC97/9x0eCsAEPgwAVLshdpENIxcAAAAASUVORK5CYII=) repeat top left;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[class*='btn'],
button,
.pointer {
  cursor: pointer;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

picture {
  display: block;
}

blockquote,
body,
button,
dd,
dl,
dt,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
lengend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-appearance: none;
}

/* 解决ios下不垂直居中的问题 */

input::-webkit-input-placeholder {
  line-height: normal;
}

input {
  caret-color: #ff9800;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.elli-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*  单行超出省略 */

.elli {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.hidden {
  display: none !important;
}

.clearfix {
  overflow: auto;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.c-abs-pic-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-abs-pic-wrap picture {
  width: 100%;
  font-size: 0;
  display: block;
}

.c-abs-pic-wrap div {
  width: 100% !important;
}

.c-abs-pic-wrap img {
  width: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: '\5FAE\8F6F\96C5\9ED1', Tahoma, Helvetica, Arial, '\5B8B\4F53', sans-serif;
  margin: 0 auto !important;
  position: relative;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}

#__next {
  height: 100%;
}

.app-wrap {
  position: relative;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  margin: 0 auto !important;
  background-color: #f7f7f7;
  overflow: hidden;
}

[data-dpr="1"] .app-wrap {
  max-width: 540px;
  min-width: 320px;
}

[data-dpr="2"] .app-wrap {
  max-width: 1080px;
  min-width: 640px;
}

[data-dpr="3"] .app-wrap {
  max-width: 1620px;
  min-width: 960px;
}

/* 页面区域 */

.page-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

/* 内容滚动区域 */

.scroll-content-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 1.333333rem;
  -webkit-overflow-scrolling: touch;
}

.c-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.c-flexc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.c-flex-grow1 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.c-flex-shrink {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}