@-webkit-keyframes loadingEllipsis {
  0% {
    content: '.';
  }

  50% {
    content: '..';
  }

  100% {
    content: '...';
  }
}

@-moz-keyframes loadingEllipsis {
  0% {
    content: '.';
  }

  50% {
    content: '..';
  }

  100% {
    content: '...';
  }
}

@keyframes loadingEllipsis {
  0% {
    content: '.';
  }

  50% {
    content: '..';
  }

  100% {
    content: '...';
  }
}

@-webkit-keyframes upAndDown {
  0% {
    -webkit-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }

  50% {
    -webkit-transform: scale(1, 1.08) translateY(-4%);
    transform: scale(1, 1.08) translateY(-4%);
  }

  100% {
    -webkit-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }
}

@-moz-keyframes upAndDown {
  0% {
    -moz-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }

  50% {
    -moz-transform: scale(1, 1.08) translateY(-4%);
    transform: scale(1, 1.08) translateY(-4%);
  }

  100% {
    -moz-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }
}

@keyframes upAndDown {
  0% {
    -webkit-transform: scale(1, 0.9) translateY(21%);
    -moz-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }

  50% {
    -webkit-transform: scale(1, 1.08) translateY(-4%);
    -moz-transform: scale(1, 1.08) translateY(-4%);
    transform: scale(1, 1.08) translateY(-4%);
  }

  100% {
    -webkit-transform: scale(1, 0.9) translateY(21%);
    -moz-transform: scale(1, 0.9) translateY(21%);
    transform: scale(1, 0.9) translateY(21%);
  }
}

@-webkit-keyframes scaleAnimate {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes scaleAnimate {
  0% {
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50% {
    -moz-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }

  100% {
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes scaleAnimate {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.loading-container {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: transparent;
}

.loading-container.shown-loading {
  display: block;
}

.loading-container .loading-content {
  position: absolute;
  left: 51%;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-container .loading-content .loading-fish-box {
  margin-bottom: 0.533333rem;
  position: relative;
}

[data-dpr="1"] .loading-container .loading-content .loading-fish-box {
  width: 141.5px;
  height: 141.5px;
}

[data-dpr="2"] .loading-container .loading-content .loading-fish-box {
  width: 283px;
  height: 283px;
}

[data-dpr="3"] .loading-container .loading-content .loading-fish-box {
  width: 424.5px;
  height: 424.5px;
}

.loading-container .loading-content .loading-fish-box .loading-fish-bg {
  background: url('https://img.qlchat.com/qlLive/activity/image/EZPJ7U5Z-68VW-JRAC-1585276593900-6GSOAOIDYRFD.png?x-oss-process=image/format,webp');
  top: 0;
  left: 0;
}

[data-dpr="1"] .loading-container .loading-content .loading-fish-box .loading-fish-bg {
  width: 141.5px;
  height: 141.5px;
  background-position: -1.5px -1.5px;
}

[data-dpr="2"] .loading-container .loading-content .loading-fish-box .loading-fish-bg {
  width: 283px;
  height: 283px;
  background-position: -3px -3px;
}

[data-dpr="3"] .loading-container .loading-content .loading-fish-box .loading-fish-bg {
  width: 424.5px;
  height: 424.5px;
  background-position: -4.5px -4.5px;
}

.loading-container .loading-content .loading-fish-box .loading-fish-img {
  background: url('https://img.qlchat.com/qlLive/activity/image/EZPJ7U5Z-68VW-JRAC-1585276593900-6GSOAOIDYRFD.png?x-oss-process=image/format,webp');
  top: 11%;
  left: 22%;
  -webkit-animation: upAndDown 1s 0s infinite both ease-in-out;
  -moz-animation: upAndDown 1s 0s infinite both ease-in-out;
  animation: upAndDown 1s 0s infinite both ease-in-out;
}

[data-dpr="1"] .loading-container .loading-content .loading-fish-box .loading-fish-img {
  width: 85px;
  height: 92px;
  background-position: -146px -1.5px;
}

[data-dpr="2"] .loading-container .loading-content .loading-fish-box .loading-fish-img {
  width: 170px;
  height: 184px;
  background-position: -292px -3px;
}

[data-dpr="3"] .loading-container .loading-content .loading-fish-box .loading-fish-img {
  width: 255px;
  height: 276px;
  background-position: -438px -4.5px;
}

.loading-container .loading-content .loading-fish-box .loading-fish-shadow {
  background: url('https://img.qlchat.com/qlLive/activity/image/EZPJ7U5Z-68VW-JRAC-1585276593900-6GSOAOIDYRFD.png?x-oss-process=image/format,webp');
  bottom: 10%;
  left: 36%;
  -webkit-animation: scaleAnimate 1s 0s infinite both ease-in-out;
  -moz-animation: scaleAnimate 1s 0s infinite both ease-in-out;
  animation: scaleAnimate 1s 0s infinite both ease-in-out;
}

[data-dpr="1"] .loading-container .loading-content .loading-fish-box .loading-fish-shadow {
  width: 41.5px;
  height: 6px;
  background-position: -146px -96.5px;
}

[data-dpr="2"] .loading-container .loading-content .loading-fish-box .loading-fish-shadow {
  width: 83px;
  height: 12px;
  background-position: -292px -193px;
}

[data-dpr="3"] .loading-container .loading-content .loading-fish-box .loading-fish-shadow {
  width: 124.5px;
  height: 18px;
  background-position: -438px -289.5px;
}

.loading-container .loading-content .sprite-fish {
  -webkit-background-size: 6.2rem 3.853333rem;
  position: absolute;
}

[data-dpr="1"] .loading-container .loading-content .sprite-fish {
  background-size: 232.5px 144.5px;
}

[data-dpr="2"] .loading-container .loading-content .sprite-fish {
  background-size: 465px 289px;
}

[data-dpr="3"] .loading-container .loading-content .sprite-fish {
  background-size: 697.5px 433.5px;
}

.loading-container .loading-content .loading-text {
  color: #333;
}

.loading-container .loading-content .loading-tip {
  padding: 0.266667rem 0.773333rem 0.266667rem 0.533333rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  line-height: 1;
}

.loading-container .loading-content .loading-prompt {
  position: relative;
  display: inline-block;
}

[data-dpr="1"] .loading-container .loading-content .loading-prompt {
  font-size: 13px;
}

[data-dpr="2"] .loading-container .loading-content .loading-prompt {
  font-size: 26px;
}

[data-dpr="3"] .loading-container .loading-content .loading-prompt {
  font-size: 39px;
}

.loading-container .loading-content .loading-prompt::after {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(100%, -65%);
  -moz-transform: translate(100%, -65%);
  transform: translate(100%, -65%);
  content: '...';
  color: inherit;
  margin-left: 0.066667rem;
  -webkit-animation: loadingEllipsis 0.8s linear 0s infinite alternate;
  -moz-animation: loadingEllipsis 0.8s linear 0s infinite alternate;
  animation: loadingEllipsis 0.8s linear 0s infinite alternate;
}