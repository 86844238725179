.app-warp {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

[data-dpr="1"] .app-warp {
  max-width: 640px;
  min-width: 320px;
}

[data-dpr="2"] .app-warp {
  max-width: 1280px;
  min-width: 640px;
}

[data-dpr="3"] .app-warp {
  max-width: 1920px;
  min-width: 960px;
}