.toast {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.toast.closed {
  display: none;
}

.toast .bd {
  line-height: 1.5;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 90%;
  padding: 0.2rem;
  -webkit-transition: opacity 0.6s linear;
  -moz-transition: opacity 0.6s linear;
  transition: opacity 0.6s linear;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  color: #fff;
  -webkit-border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  background-color: rgba(0, 0, 0, 0.8);
}

.toast .bd.big {
  width: 5.333333rem;
  height: 4rem;
  padding-top: 0.4rem;
  -webkit-border-radius: 0.4rem;
  border-radius: 0.4rem;
}

.toast .bd.big .cnt {
  position: absolute;
  bottom: 0.266667rem;
  left: 0;
  right: 0;
  margin: auto;
}

.toast .bd.big .cnt.bigfont {
  font-size: 0.48rem;
}

.toast .bd .success {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8DizOFAAAALnRSTlMAGPXY/BE3ivm9DXPjY0nBBOzIrwnRKSDw3qWcZU9AHAKTW2noUa5+M7Ohg3QT/RXkfgAABA5JREFUaN60l+uSqjAQhJss4aYguyLgdVHXy67K+z/eqVMmGRRFkOT7RxXFkJ7uGUBn+Co8zfzJMGe2zfLhxJ+dwhWHUWLnPB6WDxmOz04MI1iLfV42ku8XFjQTLHy7bIHtLwLow5mzsjVs7kALxXJUdmS0LNCb3aR8g8mur8hf9TZOx+42OmY8KYqEZ8do646ndQN89RGcz+8e6PmHVfDQfKuD79294JzjTcLPW9fMlkGj85ezWw9+hniH79FtTsKgRerC29SNvtGZqHpcts7QkmzNqoeO0I3YrZZ1LXTAcqul3biTzBUze+4FHbm4XsXeHeROh5U2pXiDtGKQYesnONTenw3eZPNDjXZa2opaNOZ4Gz4mk0StXtVT3R2gFwN6UgvhInX31EFPnKmqHL28lyk3cvSGq3Qw54Ubla9+E2gg+VUOSxvzq3L0F0ML8Z9K1XfDXUqZNbSxVr17fhZXnRcaUWd2nxpa9TeGRmLV5+hJgz+lJgm0knxJgz1u80jml0MzXOZ5hAeEMusOtOPIqRSiBpdCD2CAgRS7ruZc7gUYQW6MeU0MW+xBDiNwsSXt+0ZK421giI2MDG7Yke3MQKHZoUIxEY5OYYxUOHtSgFjSUDMFDeRlXQZ2gUEurNZOx+SB60d27jPMLBjFYndZDljjEta+mlmAKwuR7QyGycSUWuCKf730YZR6JcumzWGYUGhrkdKkvEkCVtV6f72YQQNJ6A44njO71toDQJzTQOmLNfm/4FI8hkZkHtP08AINdT9ebZrAoxlyJqfpqVvayUtfn+nb4KCtbunFeMqBvnPEX8tKR12yzjNW4n8G4EKeQFvdD6spUGJocPkKU7N1iakUOCTRzdclS4U40So2X5eW8knOkq3ZusRWzEkZrMhsXSKSY0N8Xx7N1iWO4ltTxjhDM0m0s3TURSaDnMtcNXIcliXb9q+r5kYOsSGT5vNedRn0r4tEbH+ISVK0cUQ56F0XhZiU/6o3dxyGYRiGol06GB2LHiAtuuX+xysyvdWibH1O8IDAsSmRnAN/T8guLuC5T327Q3Zx+dSTh+s4IXu4HK7Z32lA9nD5nbhADGSdywXClWkg61yuTB4JC1nm8kjwLJrIKpdnESFgIn9ELkIA6WMiq1ykj1HsDScXsWeVt8PFRd7aBf3wcBH0wggzHFxGGGVoGzqXoU0aU4/rgLwULmOqNpi/f4+nYh8wmKetItKWL1nrprQFW9pKMWuJmr82Dl6UV7AGYs2QCvZPpOFVxeILMzXr2LhBxnUlqz4knFArjrE/gFIvcrM5ZFQxVrUzSFY1OrcrLFg5HrkjEFo9Ars69Nsh5rww2N0nyr4kvN+truAsaPSspMglnM61I0fRqnO1LKBMF1Uf/ANIwO4aJqfdzwAAAABJRU5ErkJggg==) center center no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  margin: 0.266667rem auto;
}

.toast .bd.is-closing {
  opacity: 0;
}

.toast .cnt {
  text-align: center;
}

[data-dpr="1"] .toast .cnt {
  font-size: 14px;
}

[data-dpr="2"] .toast .cnt {
  font-size: 28px;
}

[data-dpr="3"] .toast .cnt {
  font-size: 42px;
}

.toast .cnt.bigfont {
  font-size: 0.48rem;
}